import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { ChatUserstate, Client } from 'tmi.js';
import { Midi } from "@tonejs/midi";
import * as Tone from 'tone';
import * as _ from 'lodash';

// eslint-disable-next-line import/no-webpack-loader-syntax
const Timidity = require('!transform-loader?brfs!timidity');

type OcarinaRouteParams = { channelName: string, botName: string, authToken: string };
type OcarinaState = { isLoading: boolean; testValue: string, testMidiUrl: string, showTestUI: boolean };

type OcarinaPitchOctave = { pitch: string; octave: number; sharp?: boolean; flat?: boolean; };
type OcarinaNote = { note: OcarinaPitchOctave[]; attackTime: Tone.Unit.Time; noteLength: number; }

export default class Ocarina extends React.Component<RouteComponentProps<OcarinaRouteParams>, OcarinaState> {
    static displayName = Ocarina.name;

    state: OcarinaState = {
        isLoading: true,
        showTestUI: false,
        testValue: "",
        testMidiUrl: ""
    };

    twitchClient: Client;
    audioContext: AudioContext;
    audioNode: AudioNode;
    synth: Tone.Sampler;
    songSynths: Tone.PolySynth[];
    midiPlayer: any;

    componentDidMount() {
        var self = this;
        var channelName = this.props.match.params.channelName;
        var authToken = this.props.match.params.authToken;
        var botName = this.props.match.params.botName;

        let connectPromise = new Promise(() => true);
        if (channelName && authToken && botName) {
            console.log("connecting to twitch channel: ", channelName);

            this.twitchClient = new Client({
                options: { debug: true, messagesLogLevel: 'info' },
                connection: {
                    reconnect: true,
                    secure: true
                },
                identity: {
                    username: botName,
                    password: `oauth:${authToken}`
                },
                channels: [channelName]
            });

            connectPromise = this.twitchClient.connect().catch(console.error);
            this.twitchClient.on('message', this.onTwitchMessage.bind(self));
        } else {
            this.setState({ showTestUI: true });
        }

        self.synth = this.createSampler().toDestination();
        self.songSynths = [];
        self.midiPlayer = new Timidity("timidity");
        console.log(self.midiPlayer);

        Promise.all([connectPromise, Tone.loaded()])
            .then(() => Tone.start())
            .then(() => {
                this.setState({ isLoading: false });
                console.log("bot data loaded");
            });
    }

    onTwitchMessage(channel: string, context: ChatUserstate, msg: string, self: boolean) {
        if (self) return;
        //if (this.state.isLoading) return;

        if (msg.toLowerCase() === "!ocarina") {
            this.twitchClient.say(channel, "Usage: !ocarina notes ( jyeotoUp jyeotoDown jyeotoLeft jyeotoRight jyeotoA ) (eg: !ocarina ^vA~ ^vA~) | Note Modifiers: ~ lengthen, / shorten, # sharp, b flat, [notes] chord, + increase octave, - decrease octave, . is a pause.");
            return;
        }

        let lowerMsg = msg.toLowerCase();

        // dodgy way to detect followers (maybe?)
        if (context.subscriber
            || (context.badges?.broadcaster ?? false)
            || (context.badges?.founder ?? false)
            || context.mod) {
            if (lowerMsg.startsWith("!ocarina")) {
                let ocarinaText = lowerMsg.substring("!ocarina".length + 1);
                this.playSound(channel, ocarinaText);
            }
        }

        if (context.mod) {
            if (lowerMsg.startsWith("!song")) {
                let midiUrlText = lowerMsg.substring("!song".length + 1);
                this.playMidi(channel, midiUrlText);
            }
        }
    }

    createSampler(): Tone.Sampler {
        return new Tone.Sampler({
            urls: {
                "A4": "A.wav",
                "B4": "B.wav",
                "D4": "D.wav",
                "D5": "D2.wav",
                "F4": "F.wav"
            },
            release: 0.4,
            baseUrl: "/notes/",
            attack: 0.02,
            volume: -6
        })
    }

    async stopSong() {
        while (this.songSynths.length) {
            const synth = this.songSynths.shift();
            synth?.disconnect();
        }

        this.midiPlayer.pause();
    }

    async playSong(channel: string | null, midiUrl: string) {
        await this.stopSong();

        const midi = await Midi.fromUrl(midiUrl);

        if (midi) {
            midi.tracks.forEach(_ => {
                const synth = new Tone.PolySynth(Tone.AMSynth, {
                    envelope: {
                        attack: 0.02,
                        decay: 0.1,
                        sustain: 0.3,
                        release: 1,
                    },
                    volume: -6
                }).toDestination();

                this.songSynths.push(synth);
            });

            await Tone.loaded();

            midi.tracks.forEach((track, i) => {
                const now = Tone.now() + 0.5;
                const synth = this.songSynths[i];

                track.notes.forEach(note => {
                    synth.triggerAttackRelease(note.name, note.duration, note.time + now, note.velocity);
                });
            });
        }
    }

    async playMidi(channel: string | null, midiUrl: string) {
        this.midiPlayer.pause();

        if (midiUrl === "stop") {
            console.log("midi should have paused");
            return;
        }

        try {
            console.log("playing ", midiUrl);
            this.midiPlayer.load(midiUrl);
            this.midiPlayer.play();
        } catch (e) {
            console.error(e);
        }
    }

    async playSound(channel: string | null, noteText: string) {
        var synth = this.synth;
        
        // A = D
        // Down = F
        // Up = D2
        // Right = A
        // Left = B

        // replace emotes in text
        noteText = _.chain(noteText)
            .replace(/jyeotoup/g, "^")
            .replace(/jyeotodown/g, "v")
            .replace(/jyeotoleft/g, "<")
            .replace(/jyeotoright/g, ">")
            .replace(/jyeotoa/g, "a")
            .replace(/ /g, "")
            .value();

        console.log("playing: ", noteText);

        var timePerNoteSection = 0.3;
        var attackTime = Tone.now();
        var notes: OcarinaNote[] = [];
        var currentNoteLetters: OcarinaPitchOctave[] = [];
        var currentNote: OcarinaNote | null = null;
        var tildeCount = 0;
        var buildingChord = false;

        for (var i = 0; i < noteText.length; i++) {
            var pushNote = false;
            var lastNote = _.last(currentNote?.note);

            switch (noteText[i]) {
                case '[':
                    buildingChord = true;
                    break;
                case ']':
                    buildingChord = false;
                    pushNote = true;
                    break;
                case '^':
                    currentNoteLetters.push({ pitch: 'D', octave: 5 });
                    pushNote = true;
                    break;
                case '<':
                    currentNoteLetters.push({ pitch: 'B', octave: 4 });
                    pushNote = true;
                    break;
                case '>':
                    currentNoteLetters.push({ pitch: 'A', octave: 4 });
                    pushNote = true;
                    break;
                case 'v':
                    currentNoteLetters.push({ pitch: 'F', octave: 4 });
                    pushNote = true;
                    break;
                case 'a':
                    currentNoteLetters.push({ pitch: 'D', octave: 4 });
                    pushNote = true;
                    break;
                case '.':
                    currentNoteLetters = [];
                    pushNote = true;
                    break;
                case '~':
                    if (currentNote) {
                        currentNote.noteLength += timePerNoteSection;
                        attackTime += timePerNoteSection;
                        tildeCount += 1;

                        if (tildeCount > 7) {
                            currentNoteLetters = currentNote.note;
                            attackTime -= 0.7;
                            pushNote = true;
                            tildeCount = 0;
                        }
                    }
                    break;
                case '/':
                    if (currentNote) {
                        currentNote.noteLength -= 0.08;
                        attackTime -= 0.08;
                    }
                    break;
                case '+':
                    if (lastNote) {
                        lastNote.octave += 1;
                        if (lastNote.octave > 7) {
                            lastNote.octave = 7;
                        }
                    }
                    break;
                case '-':
                    if (lastNote) {
                        lastNote.octave -= 1;
                        if (lastNote.octave < 0) {
                            lastNote.octave = 0;
                        }
                    }
                    break;
                case '#':
                    if (lastNote) {
                        lastNote.sharp = true;
                        if (lastNote.flat) lastNote.flat = false;
                    }
                    break;
                case 'b':
                    if (lastNote) {
                        lastNote.flat = true;
                        if (lastNote.sharp) lastNote.sharp = false;
                    }
                    break;
            }

            if (pushNote && !buildingChord) {
                let note = { note: currentNoteLetters, attackTime: attackTime, noteLength: timePerNoteSection };

                currentNoteLetters = [];
                currentNote = note;
                notes.push(note);

                tildeCount = 0;
                attackTime = attackTime + note.noteLength;
            }
        }

        var totalSongLength = _.sumBy(notes, n => n.noteLength);
        if (totalSongLength >= 10 && channel) {
            this.twitchClient.say(channel, "Your notes are too powerful traveller.");
            return;
        }

        notes.forEach(note => {
            if (note.note.length > 0) {
                let playbackNotes = _.map(note.note, n => `${n.pitch}${n.sharp ? '#' : ''}${n.flat ? 'b' : ''}${n.octave}`);

                synth.triggerAttackRelease(playbackNotes, note.noteLength, note.attackTime);
            }
        });
    }

    playInputSound() {
        this.playSound(null, this.state.testValue.toLowerCase());
    }

    playInputSong() {
        this.playMidi(null, this.state.testMidiUrl.toLowerCase());
    }

    stopInputsong() {
        this.stopSong();
    }

    playFormSound(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        this.playSound(null, this.state.testValue.toLowerCase());
    }

    playFormSong(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        this.playMidi(null, this.state.testMidiUrl.toLowerCase());
    }

    testInputChange(event: React.FormEvent<HTMLInputElement>) {
        this.setState({ testValue: event.currentTarget.value });
    }

    testMidiUrlInputChange(event: React.FormEvent<HTMLInputElement>) {
        this.setState({ testMidiUrl: event.currentTarget.value });
    }

    render() {
        if (!this.state.showTestUI) {
            return <div></div>;
        }

        return (
            <div>
                <p>Twitch Ocarina Test Page</p>
                <p>
                    Notes: <strong>^</strong> <strong>v</strong> <strong>&lt;</strong> <strong>&gt;</strong> <strong>A</strong>
                    <br /><small>(basically just up down left right A)</small>
                </p>
                <p>Modifiers:</p>
                <ul>
                    <li><strong>~</strong> make note longer</li>
                    <li><strong>/</strong> make note shorter</li>
                    <li><strong>#</strong> make note sharp</li>
                    <li><strong>b</strong> make note flat</li>
                    <li><strong>+</strong> increase note octave</li>
                    <li><strong>-</strong> decreate note octave</li>
                    <li><strong>.</strong> introduce a pause (can be modified with ~ and /)</li>
                    <li><strong>[notes]</strong> build a chord (can be modified with ~ and / after the ])</li>
                </ul>

                <div>
                    <form onSubmit={this.playFormSound.bind(this)}>
                        <input type="text" value={this.state.testValue} onChange={this.testInputChange.bind(this)} />
                        <button type="button" onClick={this.playInputSound.bind(this)}>Play</button>
                    </form>
                </div>

                <div>
                    <form onSubmit={this.playFormSong.bind(this)}>
                        <input type="text" value={this.state.testMidiUrl} onChange={this.testMidiUrlInputChange.bind(this)} />
                        <button type="button" onClick={this.playInputSong.bind(this)}>Play MIDI URL</button>
                        <button type="button" onClick={this.stopInputsong.bind(this)}>Stop</button>
                    </form>
                </div>
            </div>
        );
    }
}
